const TechnologyColors = {
    "PHP8": "bg-indigo-300 text-black",
    "PHP7": "bg-indigo-300 text-black",
    "PHP5": "bg-indigo-300 text-black",
    "PHP": "bg-indigo-300 text-black",
    "Javascript": "bg-yellow-300 text-black",
    "NodeJS": "bg-lime-600 text-white",
    "Golang": "bg-cyan-400 text-black",
    "Java": "bg-blue-500 text-white",
    "Symfony": "bg-green-500 text-white",
    "Symfony5": "bg-green-500 text-white",
    "Symfony4": "bg-green-500 text-white",
    "Symfony3": "bg-green-500 text-white",
    "Laravel": "bg-rose-500 text-white",
    "CakePHP": "bg-red-600 text-white",
    "AWS": "bg-orange-600 text-white",
    "AWS EC2": "bg-orange-600 text-white",
    "AWS SNS": "bg-orange-600 text-white",
    "AWS SQS": "bg-orange-600 text-white",
    "AWS RDS": "bg-orange-600 text-white",
    "AWS Athena": "bg-orange-600 text-white",
    "AWS S3": "bg-orange-600 text-white",
    "AWS Lambda": "bg-orange-600 text-white",
    "GCP": "bg-blue-600 text-white",
    "Kubernetes": "bg-blue-600 text-white",
    "Docker": "bg-cyan-600 text-white",
    "Terraform": "bg-indigo-700 text-white",
    "Mutagen": "bg-teal-500 text-white",
    "MySQL": "bg-cyan-700 text-white",
    "PostgreSQL": "bg-lightBlue-800 text-white",
    "Oracle NetSuite": "bg-lightBlue-900 text-white",
    "SuiteScript": "bg-fuchsia-800 text-white",
    "Redis": "bg-red-600 text-white",
    "RabbitMQ": "bg-orange-500 text-white",
    "MongoDB": "bg-green-700 text-white",
    "InfluxDB": "bg-cyan-300 text-gray",
    "Elasticsearch": "bg-lime-500 text-gray",
    "ELK": "bg-emerald-500 text-white",
    "Grafana": "bg-amber-400 text-gray",
    "Github": "bg-violet-400 text-white",
    "Github Actions": "bg-violet-400 text-white",
    "Jenkins": "bg-orange-100 text-gray",
    "Redmine": "bg-red-700 text-white",
    "Jira": "bg-blue-500 text-white",
    "GitLab": "bg-orange-500 text-white",
    "React": "bg-cyan-300 text-black",
    "JSX": "bg-cyan-300 text-black",
    "Redux": "bg-violet-800 text-white",
    "Hadoop": "bg-cyan-500 text-white",
    "Kafka": "bg-black text-white",
    "Solr": "bg-orange-700 text-white",
    "SaltStack": "bg-lightBlue-300 text-black",
    "Vagrant": "bg-blue-700 text-white",
    "Ruby": "bg-red-500 text-white",
    "Elixir": "bg-violet-900 text-white",
    "Cassandra": "bg-blue-300 text-black",
    "AngularJS": "bg-red-600 text-white",
    "nginx": "bg-green-600 text-white",
    "Python": "bg-yellow-200 text-black",
    "ASM": "bg-gray-500 text-white",
    "C": "bg-blue-800 text-white",
    "C++": "bg-blue-800 text-white",
    "C#": "bg-purple-800 text-white",
    "Delphi": "bg-blueGray-500 text-white",
    "Typescript": "bg-blue-500 text-white",
    "Embedded MCU": "bg-green-400 text-white",
    "Ruby on Rails": "bg-red-600 text-white",
    "Spring Boot": "bg-lime-500 text-white",
    "Babel": "bg-yellow-300 text-black",
    "Bootstrap": "bg-violet-900 text-white",
    "CSS": "bg-cyan-300 text-white",
    "ES6": "bg-yellow-300 text-black",
    "ES7": "bg-yellow-300 text-black",
    "HTML5": "bg-red-500 text-white",
    "Tailwindcss": "bg-cyan-500 text-white",
    "WebPack": "bg-lightBlue-300 text-black",
    "Cordova": "bg-blueGray-400 text-white",
    "React Native": "bg-cyan-300 text-black",
    "Hive": "bg-yellow-200 text-black",
    "Presto": "bg-blue-300 text-black",
    "Spark": "bg-orange-500 text-white",
    "Algolia": "bg-indigo-600 text-white",
    "CouchBase": "bg-red-600 text-white",
    "Lucene": "bg-lime-100 text-black",
    "MemSQL": "bg-fuchsia-400 text-black",
    "MariaDB": "bg-yellow-600 text-white",
    "Memcache": "bg-teal-500 text-white",
    "CVS": "bg-orange-500 text-white",
    "Git": "bg-red-500 text-white",
    "BitBucket": "bg-blue-500 text-white",
    "Mercurial": "bg-gray-400 text-white",
    "SVN": "bg-indigo-200 text-black",
    "VirtualBox": "bg-lightBlue-800 text-white",
    "VMware ESXi": "bg-blue-300 text-black",
    "VMware Fusion": "bg-blue-300 text-black",
    "VMware Workstation": "bg-blue-300 text-black",
    "Ansible": "bg-red-600 text-white",
    "Chef": "bg-orange-500 text-white",
    "Puppet": "bg-orange-300 text-black",
    "Travis": "bg-amber-100 text-black",
    "New Relic": "bg-teal-300 text-black",
    "Sentry": "bg-indigo-900 text-white",
    "DigitalOcean": "bg-blue-500 text-white",
    "Express": "bg-lime-500 text-black",
    "RethinkDB": "bg-teal-600 text-white",
    "Crystal": "bg-coolGray-700 text-white",
    "Rust": "bg-amber-800 text-white",
    "FreeRTOS": "bg-lime-500 text-black",
};

export default TechnologyColors;
